@media screen and (min-width: 800px) {
  div.collapsed {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  div.full {
    display: none;
  }
}
#hamburger {
  color: #ff7b00;
}

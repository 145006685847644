#unit {
  background-color: #ffffff;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;

  /* her */
}

#brand {
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
}

#brand1 {
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  margin-left: 1%;
}

#brand2 {
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  margin-left: 1%;
}
#brand3 {
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  margin-left: 1%;
}
#brand4 {
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  margin-left: 1%;
}

#brand5 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

#brand1:hover {
  color: rgb(247, 130, 35);
}
#brand5:hover {
  color: rgb(247, 130, 35);
}

body,
html {
  height: 100%;
}

.bg {
  background-image: url("../shared/bg.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-custom {
  border-color: #000000 !important;
  background-color: #000000 !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;

  margin-right: 50%;
  text-decoration-color: #ff7b00 !important ;
}
.btn-custom:hover {
  border-color: #000000 !important;
  background-color: #ff7b00 !important;
  color: #ffffff !important;

  text-decoration-color: #ffffff !important ;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger:visited {
  background-color: black !important;
  color: #ffffff !important ;
  border-color: #ff7b00 !important;
}

.btn-outline-danger:hover {
  background-color: #ff7b00 !important;
  color: #000000 !important ;

  text-decoration-color: #ffffff !important ;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

.intro {
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  color: #ff7b00;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.intro2 {
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  margin-top: 1%;
  color: #ff7b00;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
}

.orange {
  margin-top: 5%;
  height: 40px;
  width: 190px;
  font-family: Arial, Helvetica, sans-serif;
}

.col-left {
  margin: 0;
  padding: 0;
}

.col-middle {
  float: left;
}

.col-right {
  float: right;
}

.text-nowrap {
  white-space: nowrap;
}

.nav-btn {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff !important ;
}

.nav-btn:hover {
  background-color: #ff7b00 !important;
  color: #ffffff !important ;

  text-decoration-color: #ffffff !important ;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

/* apijsdpasj*/

.navbar.transparent .navbar-inner {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.navbar.transparent .navbar-inner li a {
  color: #ffffff;
  font-family: "Raleway";
  font-size: 10pt;
  letter-spacing: 3pt;
}
.navbar.transparent .nav > li > a:focus,
.navbar.transparent .nav > li > a:hover {
  color: #666666;
  text-decoration: none;
  background-color: transparent;
}
.button.navbar-toggle.collapsed:focus,
.button.navbar-toggle.collapsed:hover {
  color: #666666;
}
.navbar.transparent .navbar-brand:hover,
.navbar.transparent .navbar-brand:focus {
  background-color: transparent;
  color: #ffffff;
}
.navbar.transparent .navbar-brand {
  background-color: transparent;
  color: #ffffff;
  font-family: "Tenor Sans";
  font-size: 16pt;
  letter-spacing: 1pt;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #fc0 /* change this to any color you want! */;
}

.navbar-toggler {
  color: orange;
}
